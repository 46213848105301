.bookingtitle {
  text-align: center;
  margin: 1.5rem
}

.confirmationnumber {
  text-align: center;
  justify-content: center;
  display: flex;
  margin-bottom: 1.5rem;

}

.confirmationnumber1 {
  color: red;
  font-size: 24px;
  margin-left: 1.5rem;
}

.email {
  margin-bottom: 3rem;
}

.email span {
  color: gray;
}

.total {
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.driver {
  margin: 5px auto;
}