@import '~sass-mq';
$mq-breakpoints: (mobileS: 320px,
        mobileM: 375px,
        mobileL: 425px,
        tablet: 768px,
        laptop: 1024px,
        laptopL: 1440px,
        desktop: 2560px);



.locholder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.locationp,
.locationd {

  display: table-cell;
  height: 85px;
  vertical-align: middle;
  width: 50%;
  margin: auto 5px;
}

.locationd1 {

  display: table-cell;
height: 85px;
  vertical-align: middle;
  width: 0;
  margin: auto 5px;}

.title {
  font-size: 14px;
}

.locationp {
  border-right: solid white 1px

}

.location4 {
  display: table-cell;
  height: 85px;
  vertical-align: middle;
  width: 0%;
  margin: auto 10px;
}

.holder {
  margin-top: 12px;
  display: flex;
  margin-left: 8px;
  flex-direction: column;

}
.sizeheader {
  font-size: 24px;
  font-weight: 400;
  margin-top: 10px;
  @include mq($from: mobileL) {

  }
  @include mq($from: tablet) {

  }
  @include mq($from: laptop) {
    font-size: 30px;
  }
}






.name {
  margin-top: 3px;
  font-size: 14px;
}

.openhours {
  font-size: 14px;

}

.instructions {
  font-size: 14px;
}

.container {
  margin-top: 30px;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46%;
  height: 200px;
  ;
}

.image img {
  width: 100%;
}


.sizename {
  font-size: 16px;
  margin-top: 10px;
}


.details2 {
  position: relative;

  @include mq($from: mobileL) {

  }
  @include mq($from: tablet) {
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  @include mq($from: laptop) {
    font-size: 30px;
  }
}
.details {
  position: relative;

  @include mq($from: mobileL) {

  }
  @include mq($from: tablet) {
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  @include mq($from: laptop) {
    font-size: 30px;
  }
}

.acriss {
  width: 50%;
  height: 150px;
  margin-left: 8px;
  margin-top: 15px;
  margin-bottom: 0;


}

.included {
  height: auto;
  width: 100%;
  margin-top: 5px;
  margin-right: auto;
  margin-left: 10px;
padding-bottom: 10px;

  margin-bottom: 2px;
}

.inc img {
  width: 15px
}

.inc {
  margin: 5px;
  display: inline-block;
  text-align: center;

}

.priceholder {
  position: absolute;
  bottom: 10px;
  font-size: 20px;
  color: orange;
  width: 100%;
  text-align: right
}

.priceday {
  font-size: 32px;
  font-weight: 500;
  color: orange;
  text-align: right;
  position: absolute;
  bottom: 30px;
  width: 100%
}

.priceday span {
  font-size: 18px;
}

.resbtn {
  height: 80px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 60px;
}

.inc2 {
  margin: 3px;
  width: 50%;
  text-align: left;  font-size: 20px;

}

.inc2 img {
  width: 20px
}

.included2 {

  height: 150px;
  width: 100%;
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}


.incholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%
}

@media (min-width: 425px) {}

@media (min-width: 768px) {
  .locationd1 {

    display: table-cell;
    height: 150px;
    vertical-align: middle;
    width: 25%;
    margin: auto 31px;
  }

  .title {
    font-size: 18px;
  }

  .locationp,
  .locationd {

    height: 150px;
  }

  .location4 {
    display: table-cell;
    height: 150px;
    vertical-align: middle;
    width: 25%;
    margin: auto 10px;
  }

  .holder {
    display: flex;
    flex-direction: column;

  }

  .name {
    font-size: 16px;
  }

  .openhours {
    font-size: 22px;

  }

  .instructions {
    font-size: 20px;
  }

  .container {
    margin-top: 30px;
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%
  }

  .image img {
    width: 70%;
  }



  .sizename {
    font-size: 16px;
    margin-top: 10px;
  }



  .acriss {
    width: 22%;
    margin-left: 8px;
  }

  .included {
    height: 100px;
    width: 100%;
    margin: 5px auto;


  }

  .inc img {
    width: 15px
  }

  .inc {
    margin: 3px;
    display: inline-block;
    text-align: center;
    font-size: 16px;
  }

  .priceholder {
    position: absolute;
    bottom: 10px;
    font-size: 15px;
    color: orange;
    width: 200px;
    text-align: right
  }

  .priceday {
    font-size: 28px;
    font-weight: 500;
    color: orange;
    text-align: right;
    position: absolute;
    bottom: 30px;
    width: 200px
  }

  .priceday span {
    font-size: 16px;
  }

  .resbtn {
    height: 80px;
    align-self: center;
    justify-self: center;
    width: 80%
  }

  .inc2 {
    margin: 3px;
    width: 50%;
    text-align: left;

  }

  .inc2 img {
    width: 20px
  }

  .included2 {

    height: 150px;
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }


  .incholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%
  }
}

@media (min-width: 968px) {
  .locationd1 {

    display: table-cell;

    vertical-align: middle;
    width: 25%;
    margin: auto 31px;
  }

  .locationp,
  .locationd1 {

  }

  .location4 {
    display: table-cell;

    vertical-align: middle;
    width: 25%;
    margin: auto 10px;
  }

  .holder {
    display: flex;
    flex-direction: column;

  }

  .name {
    font-size: 20px;
  }

  .openhours {
    font-size: 28px;

  }

  .instructions {
    font-size: 20px;
  }

  .container {
    margin-top: 30px;
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%
  }

  .image img {
    width: 70%;
  }



  .sizename {
    font-size: 18px;
    margin-top: 10px;
  }

  .details {
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .acriss {
    width: 25%;
    margin-left: 8px;
  }

  .included {
    height: 100px;
    width: 100%;
    margin: 5px auto;
    margin-right: 0;
  }

  .inc img {
    width: 15px
  }

  .inc {
    margin: 3px;
    display: inline-block;
    text-align: center;
    font-size: 20px;
  }

  .priceholder {
    position: absolute;
    bottom: 10px;
    font-size: 20px;
    color: orange;
    width: 100%;
    text-align: right
  }

  .priceday {
    font-size: 32px;
    font-weight: 500;
    color: orange;
    text-align: right;
    position: absolute;
    bottom: 30px;
    width: 100%
  }

  .priceday span {
    font-size: 18px;
  }

  .resbtn {
    height: 80px;
    align-self: center;
    justify-self: center;
    width: 80%
  }

  .inc2 {
    margin: 3px;
    width: 50%;
    text-align: left;


  }

  .inc2 img {
    width: 20px
  }

  .included2 {

    height: 150px;
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }



  .incholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%
  }
}

@media (min-width: 1024px) {
  .name {
    font-size: 20px;
  }



  .instructions {
    font-size: 20px;
  }
}

@media (min-width: 1440px) {


  .locholder {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .locationp,
  .locationd {

    display: table-cell;

    vertical-align: middle;
    width: 25%;
    margin: auto 10px;
  }

  .locationd1 {

    display: table-cell;

    vertical-align: middle;
    width: 25%;
    margin: auto 31px;
  }

  .locationp,
  .locationd1 {


  }

  .location4 {
    display: table-cell;

    vertical-align: middle;
    width: 25%;
    margin: auto 10px;
  }

  .holder {
    display: flex;
    flex-direction: column;

  }

  .name {
    font-size: 20px;
  }

  .openhours {
    font-size: 28px;

  }

  .instructions {
    font-size: 20px;
  }

  .container {
    margin-top: 30px;
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%
  }

  .image img {
    width: 70%;
  }



  .sizename {
    font-size: 18px;
    margin-top: 10px;
  }

  .details {
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .acriss {
    width: 25%;
    margin-left: 8px;
  }

  .included {
    height: 100px;
    width: 100%;
    margin: 5px auto;
    margin-right: 0;
  }

  .inc img {
    width: 15px
  }

  .inc {
    margin: 3px;
    display: inline-block;
    text-align: center;
  }

  .priceholder {
    position: absolute;
    bottom: 10px;
    font-size: 20px;
    color: orange;
    width: 100%;
    text-align: right
  }

  .priceday {
    font-size: 32px;
    font-weight: 500;
    color: orange;
    text-align: right;
    position: absolute;
    bottom: 30px;
    width: 100%
  }

  .priceday span {
    font-size: 18px;
  }

  .resbtn {
    height: 80px;
    align-self: center;
    justify-self: center;
    width: 80%
  }

  .inc2 {
    margin: 3px;
    width: 50%;
    text-align: left;

  }

  .inc2 img {
    width: 20px
  }

  .included2 {

    height: 150px;
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }



  .incholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%
  }
}
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 2) {
  .inc {
    margin: 3px;
    width: 100%;
    text-align: left;  font-size: 16px;

  }




  /* STYLES GO HERE */}