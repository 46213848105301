body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;

  min-height: 100vh;
}

body,
html {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.p2title{
  font-size: 14px;
  font-weight: 700;
  margin-top: 8px;
  text-align: center;
  margin-bottom: 4px;
}
.p2text{
  font-size: 12px;
  margin-top: 5px;
    margin-bottom: 5px;
  text-align: justify;
}
.p2txtheading{
  margin-right: 2px;
  font-size: 16px;
  font-weight: 700;
}