.textarea textarea {
  height: 200px !important;
}
.button_holder{
  width: 100%;
  display: flex;justify-content: space-evenly;
  margin-bottom: 30px;
}
.textarea label {
  font-size: 20px;
}
.spinner {
  display: flex;
  justify-content: center;
  align-items: center
}
.extrabox input {
  width: 20px;
  height: 20px;
}
.captcha {
  width: 200px;
  display: flex;
  justify-content: flex-end;
}
.headers {
  border-top-left-radius: 5px;
  background: #e5e7e9;
  text-align: center;
  border-top-right-radius: 5px;
  margin-left: -10px;
  margin-right: -10px;
  width: auto;
}

.extrabox input {
  width: 15px;
}

.cost {
  font-size: 14px;
  margin-top: 2px;
}
.loading{
  min-height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
.cardetails {
  min-height: 300px;
  margin-bottom: 30px;
  width: 100%;
  margin-top: 30px;
}

.showshadow {
  box-shadow: 2px 2px 8px 1px rgba(204, 204, 204, 1);
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.extrabox {
  justify-content: center;
  display: flex;
  align-items: baseline;
}

.holder {
  display: flex;
  flex-direction: column;
}

.name {
  margin-top: 10px;
  font-size: 14px;
}

.openhours {
  font-size: 16px;
}

.instructions {
  font-size: 14px;
}

.container {
  margin-top: 30px;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 200px;
}

.image img {
  width: 150%;
}
.valid{
  color:green !important;
    width: 65%;
}
.valid:focus{

    outline: #1c7430;
}
.notvalid{
    width: 65%;
}
.sizeheader {
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
}

.sizename {
  font-size: 14px;
  margin-top: 10px;
}

.details {
  position: relative;
  /* display: flex;
  justify-content: flex-start; */
}

.ccform {
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.ccinfo {
width: 100%;
    height: 100%;
  margin-bottom: 30px;
}

.paypal img {
    display: none;
}

.ccimg1 {
    display: flex;
    justify-content: space-evenly;
}

.norton img {
    width: 110px;

}

.mcafee img {
    width: 110px;

}

.ccimg {
    display: none;
}

.ccholder1{
  display:flex;
  margin-bottom: 15px;
}
.ccholder{

  margin-top: auto;
margin-left: 30px;
  margin-bottom: auto;
  width: auto;
}
.ccheader{
  text-align: center;
}
.ccnumber{
    width: 100%;

    margin-right: 20px;
  display: flex;
}
.ccexp{
display: flex;
  justify-content: space-around;
    width: 100%;

}
.ccyear input{
    width: 40%; margin: auto;
}
.ccmonth input{
    width: 40%;
  margin-right: 15px;



}
.ccyear {
  width: 100%;

}
.ccmonth{
  width: 100%;
  display:flex;

}
.exptext{
  margin: auto;
}
.ccnumbertxt{
  width: 63px;
  margin-right: 11px;
}
.cvvtext{
margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
}
.ccv{
  margin-top: 20px;
    width: 100%;  display: flex;justify-content: start;

}
.ccv input{
  width: 30%;
  margin-left: 36px;
}
.acriss {
  width: 40%;
  height: 150px;
  margin-left: 8px;
  margin-top: 15px;
  margin-bottom: 0;
}

.included {
  height: 50px;
  width: 100%;
  margin: 5px auto;
  margin-right: 0;
}

.inc img {
  width: 15px;
}

.inc {
  margin: 5px;
  /* display: inline-block; */
  text-align: center;
}

.priceholder {
  font-size: 20px;
  color: orange;
  width: 100%;
  text-align: right;
 margin-top:-8px;
}

.priceday {
  font-size: 32px;
  font-weight: 500;
  color: orange;
  text-align: right;
  align-self: flex-end;
}

.pricerow {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100px;
}

.priceday span {
  font-size: 20px;
}

.resbtn {
  height: 80px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
}

.inc2 {
  margin-top: 0;
  margin-right: 0;
  padding-left: 20px;
  margin-bottom: 0;
  width: 100%;
  text-align: left;
  font-size: 12px;
}

.inc2 img {
  width: 10px;
}

.included2 {
  height: 150px;
  width: 100%;
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.details2 {
  position: relative;
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  z-index: 5000;
}

.incholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (min-width: 425px) {
}

@media (min-width: 768px) {
  .locationd {
    display: table-cell;
    height: 110px;
    vertical-align: middle;
    width: 25%;
    margin: auto 31px;
  }
  .image img {
    width: 100%;
  }
  .locationp,
  .locationd {
    border-right: solid white 1px;
  }

  .extrabox input {
    width: 20px;
  }



  .location4 {
    display: table-cell;
    height: 110px;
    vertical-align: middle;
    width: 25%;
    margin: auto 10px;
  }

  .holder {
    display: flex;
    flex-direction: column;
  }

  .name {
    font-size: 16px;
  }

  .openhours {
    font-size: 24px;
  }

  .instructions {
    font-size: 20px;
  }

  .container {
    margin-top: 30px;
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
  }


  .sizeheader {
    font-size: 24px;
    font-weight: 400;
    margin-top: 10px;
  }

  .sizename {
    font-size: 16px;
    margin-top: 10px;
  }

  .details {
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .acriss {
    width: 22%;
    margin-left: 8px;
  }

  .included {
    height: 100px;
    width: 100%;
    margin: 5px auto;
  }

  .inc img {
    width: 15px;
  }

  .inc {
    margin: 3px;
    display: inline-block;
    text-align: center;
  }

  .priceday span {
    font-size: 16px;
  }

  .resbtn {
    height: 80px;
    align-self: center;
    justify-self: center;
    width: 80%;
  }

  .inc2 {
    margin: 3px;
    width: 100%;
    text-align: left;
    font-size: 16px;
  }

  .inc2 img {
    width: 20px;
  }

  .included2 {
    height: 150px;
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .details2 {
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .incholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

    .ccimg {
        justify-content: center;
        align-items: space-evenly;
        display: flex;
        flex-direction: column;
    }

    .ccform {
        display: flex;
        justify-content: center;
        padding: 20px;
    }

    .paypal img {
        display: block;
        margin: auto;
        height:95px
    }

    .paypal {
        margin-bottom: 10px;
    }



}

@media (min-width: 968px) {
  .locationd {
    display: table-cell;
    height: 110px;
    vertical-align: middle;
    width: 25%;
    margin: auto 31px;
  }
  .paypal img {
    display: block;
    margin: auto;
    height:120px
  }
  .cost {
    font-size: 20px;
    margin-top: 2px;
  }
  .locationp,
  .locationd {
    border-right: solid white 1px;
  }

  .location4 {
    display: table-cell;
    height: 110px;
    vertical-align: middle;
    width: 25%;
    margin: auto 10px;
  }

  .holder {
    display: flex;
    flex-direction: column;
  }

  .name {
    font-size: 20px;
  }

  .openhours {
    font-size: 30px;
  }

  .instructions {
    font-size: 20px;
  }

  .container {
    margin-top: 30px;
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
  }


  .sizeheader {
    font-size: 28px;
    font-weight: 400;
    margin-top: 10px;
  }

  .sizename {
    font-size: 18px;
    margin-top: 10px;
  }

  .details {
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .acriss {
    width: 25%;
    margin-left: 8px;
  }

  .included {
    height: 100px;
    width: 100%;
    margin: 5px auto;
    margin-right: 0;
  }

  .inc img {
    width: 15px;
  }

  .inc {
    margin: 3px;
    display: inline-block;
    text-align: center;
  }

  .priceday span {
    font-size: 18px;
  }

  .resbtn {
    height: 80px;
    align-self: center;
    justify-self: center;
    width: 80%;
  }

  .inc2 {
    margin: 3px;
    width: 100%;
    text-align: left;
  }

  .inc2 img {
    width: 20px;
  }

  .included2 {
    height: 150px;
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .details2 {
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .incholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .name {
    font-size: 20px;
  }
  .ccnumbertxt{
    width: 63px;
    margin-right: 17px;
  }
  .openhours {
    font-size: 30px;
  }

  .instructions {
    font-size: 20px;
  }

  .ccholder1{
    display:flex;
    margin-bottom: 15px;
  }
  .ccholder{

    margin-top: auto;
    margin-left: 30px;
    margin-bottom: auto;
    width: auto;
  }
  .ccheader{
    text-align: center;
  }
  .ccnumber{
    width: 60%;
    margin-left: 40px;
    margin-right: 20px;
    display: flex;
  }
  .ccexp{
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-left: 40px;
  }
  .ccyear input{
    width: 20%; margin: auto;
  }
  .ccmonth input{
    width: 20%;
    margin-right: 15px;



  }
  .alert1{
    width:100%;text-align: center;

  }
  .ccyear {
    width: 100%;

  }
  .ccmonth{
    width: 100%;
    display:flex;

  }
  .exptext{
    margin: auto;
  }
  .cvvtext{
    margin:auto;
  }

  .ccv{
    margin-top: 0;
    width: 25%;  display: flex;

  }
  .ccv input{
    width: 65%;
    margin-left: 16px;
  }
}

@media (min-width: 1440px) {
  .locholder {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .locationp {
    display: table-cell;
    height: 110px;
    vertical-align: middle;
    width: 25%;
    margin: auto 10px;
  }

  .locationd {
    display: table-cell;
    height: 110px;
    vertical-align: middle;
    width: 25%;
    margin: auto 31px;
  }

  .locationp,
  .locationd {
    border-right: solid white 1px;
  }

  .location4 {
    display: table-cell;
    height: 110px;
    vertical-align: middle;
    width: 25%;
    margin: auto 10px;
  }

  .holder {
    display: flex;
    flex-direction: column;
  }

  .name {
    font-size: 20px;
  }

  .openhours {
    font-size: 30px;
  }

  .instructions {
    font-size: 20px;
  }

  .container {
    margin-top: 30px;
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
  }



  .sizeheader {
    font-size: 28px;
    font-weight: 400;
    margin-top: 10px;
  }

  .sizename {
    font-size: 18px;
    margin-top: 10px;
  }

  .details {
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .acriss {
    width: 25%;
    margin-left: 8px;
  }

  .included {
    height: 100px;
    width: 100%;
    margin: 5px auto;
    margin-right: 0;
  }

  .inc img {
    width: 15px;
  }

  .inc {
    margin: 3px;
    display: inline-block;
    text-align: center;
  }

  .priceday span {
    font-size: 18px;
  }

  .resbtn {
    height: 80px;
    align-self: center;
    justify-self: center;
    width: 80%;
  }

  .inc2 {
    margin: 3px;
    width: 100%;
    text-align: left;
    font-size: 20px;
  }

  .inc2 img {
    width: 20px;
  }

  .included2 {
    height: 150px;
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .details2 {
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .incholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
