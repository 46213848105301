@import '~sass-mq';
$mq-breakpoints: (mobileS: 320px,
        mobileM: 375px,
        mobileL: 425px,
        tablet: 768px,
        laptop: 1024px,
        laptopL: 1440px,
        desktop: 2560px);

.stylecontainer {
  background: lightgray;
  min-height: 200px;
  height: auto;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.styledtime {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
}

.styledbutton {
  background: #ff6205 !important;
  height: 100%;
  font-size: 30px;
  width: 100%;
  color: white;
  font-weight: 800;

  border-radius: 8px;
  @include mq($from: mobileL) {
    font-size: 24px;
  }
  @include mq($from: tablet) {
    font-size: 21px;
  }
  @include mq($from: laptop) {
    font-size: 30px;
  }
}


.container {
  height: auto;

  margin-bottom: 0;
  padding-bottom: 200px;
}

.card {
  border: none !important;
}

.card img {
  margin-top: 20px;
  border-radius: 8px !important;


}

select.selsize{
 font-size: 14px;
}
.btnholder {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 15px;
}

.react-datepicker__input-container {
  font-size: 14px !important;
}

.srow {
  margin-left: 0 !important;
  width: 100%;


}

.container img {
  border-radius: 8px !important;
  width: auto;
}


.imgradius {
  border-radius: 8px !important;

}

.imgradius img {
  width: 125px;
  text-align: center;
  border-radius: 8px !important;
  margin-bottom: 10px;

}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center
}

@media (min-width: 768px) {
  .imgradius img {
    width: 175px;
  }
  .container {
    height: auto;
    margin-top: 30px;
    margin-bottom: 0;
    padding-bottom: 200px;
  }
}

@media (min-width: 1024px) {
  .imgradius img {
    width: 225px;
  }
}