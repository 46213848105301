body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;

  min-height: 100vh;
}

body,
html {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.p2title{
  font-size: 14px;
  font-weight: 700;
  margin-top: 8px;
  text-align: center;
  margin-bottom: 4px;
}
.p2text{
  font-size: 12px;
  margin-top: 5px;
    margin-bottom: 5px;
  text-align: justify;
}
.p2txtheading{
  margin-right: 2px;
  font-size: 16px;
  font-weight: 700;
}
.Home_stylecontainer__2mEZ9 {
  background: lightgray;
  min-height: 200px;
  height: auto;
  display: flex;
  align-items: center;
  border-radius: 8px; }

.Home_styledtime__2lVsv {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057; }

.Home_styledbutton__2CrFL {
  background: #ff6205 !important;
  height: 100%;
  font-size: 30px;
  width: 100%;
  color: white;
  font-weight: 800;
  border-radius: 8px; }
  @media (min-width: 26.5625em) {
    .Home_styledbutton__2CrFL {
      font-size: 24px; } }
  @media (min-width: 48em) {
    .Home_styledbutton__2CrFL {
      font-size: 21px; } }
  @media (min-width: 64em) {
    .Home_styledbutton__2CrFL {
      font-size: 30px; } }

.Home_container__sCNP1 {
  height: auto;
  margin-bottom: 0;
  padding-bottom: 200px; }

.Home_card__2EPYO {
  border: none !important; }

.Home_card__2EPYO img {
  margin-top: 20px;
  border-radius: 8px !important; }

select.Home_selsize__1rj85 {
  font-size: 14px; }

.Home_btnholder__2NeBr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px; }

.Home_react-datepicker__input-container__u55oU {
  font-size: 14px !important; }

.Home_srow__16qbT {
  margin-left: 0 !important;
  width: 100%; }

.Home_container__sCNP1 img {
  border-radius: 8px !important;
  width: auto; }

.Home_imgradius__3Rp_w {
  border-radius: 8px !important; }

.Home_imgradius__3Rp_w img {
  width: 125px;
  text-align: center;
  border-radius: 8px !important;
  margin-bottom: 10px; }

.Home_spinner__1eUxc {
  display: flex;
  justify-content: center;
  align-items: center; }

@media (min-width: 768px) {
  .Home_imgradius__3Rp_w img {
    width: 175px; }
  .Home_container__sCNP1 {
    height: auto;
    margin-top: 30px;
    margin-bottom: 0;
    padding-bottom: 200px; } }

@media (min-width: 1024px) {
  .Home_imgradius__3Rp_w img {
    width: 225px; } }

.Details_textarea__3q5DD textarea {
  height: 200px !important; }

.Details_button_holder__2PzzW {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px; }

.Details_textarea__3q5DD label {
  font-size: 20px; }

.Details_spinner__1lpav {
  display: flex;
  justify-content: center;
  align-items: center; }

.Details_extrabox__4A2lA input {
  width: 20px;
  height: 20px; }

.Details_captcha__11VK5 {
  width: 200px;
  display: flex;
  justify-content: flex-end; }

.Details_headers__1hzMT {
  border-top-left-radius: 5px;
  background: #e5e7e9;
  text-align: center;
  border-top-right-radius: 5px;
  margin-left: -10px;
  margin-right: -10px;
  width: auto; }

.Details_extrabox__4A2lA input {
  width: 15px; }

.Details_cost__3oX1D {
  font-size: 14px;
  margin-top: 2px; }

.Details_loading__2Vnve {
  min-height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000; }

.Details_cardetails__3_z6S {
  min-height: 300px;
  margin-bottom: 30px;
  width: 100%;
  margin-top: 30px; }

.Details_showshadow__3DQ-F {
  box-shadow: 2px 2px 8px 1px #cccccc;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px; }

.Details_extrabox__4A2lA {
  justify-content: center;
  display: flex;
  align-items: baseline; }

.Details_holder__3w1PJ {
  display: flex;
  flex-direction: column; }

.Details_name__GKSlv {
  margin-top: 10px;
  font-size: 14px; }

.Details_openhours__1oG2p {
  font-size: 16px; }

.Details_instructions__2XJSs {
  font-size: 14px; }

.Details_container__Yqu5w {
  margin-top: 30px; }

.Details_image__1hMdQ {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 200px; }

.Details_image__1hMdQ img {
  width: 150%; }

.Details_valid__2M9d9 {
  color: green !important;
  width: 65%; }

.Details_valid__2M9d9:focus {
  outline: #1c7430; }

.Details_notvalid__3GMbc {
  width: 65%; }

.Details_sizeheader__2CUjl {
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px; }

.Details_sizename__1_hxs {
  font-size: 14px;
  margin-top: 10px; }

.Details_details__25TIV {
  position: relative;
  /* display: flex;
  justify-content: flex-start; */ }

.Details_ccform__3TFUH {
  display: flex;
  justify-content: space-around;
  width: 100%; }

.Details_ccinfo__2fQJF {
  width: 100%;
  height: 100%;
  margin-bottom: 30px; }

.Details_paypal__1yeQn img {
  display: none; }

.Details_ccimg1__1xQn9 {
  display: flex;
  justify-content: space-evenly; }

.Details_norton__2LccI img {
  width: 110px; }

.Details_mcafee__32Bll img {
  width: 110px; }

.Details_ccimg__1lrVf {
  display: none; }

.Details_ccholder1__Hw8Us {
  display: flex;
  margin-bottom: 15px; }

.Details_ccholder__2n_m9 {
  margin-top: auto;
  margin-left: 30px;
  margin-bottom: auto;
  width: auto; }

.Details_ccheader__2OcjA {
  text-align: center; }

.Details_ccnumber__2Dhfr {
  width: 100%;
  margin-right: 20px;
  display: flex; }

.Details_ccexp__cN6TG {
  display: flex;
  justify-content: space-around;
  width: 100%; }

.Details_ccyear__1jQfG input {
  width: 40%;
  margin: auto; }

.Details_ccmonth__17h5w input {
  width: 40%;
  margin-right: 15px; }

.Details_ccyear__1jQfG {
  width: 100%; }

.Details_ccmonth__17h5w {
  width: 100%;
  display: flex; }

.Details_exptext__3nA-q {
  margin: auto; }

.Details_ccnumbertxt__1Txc6 {
  width: 63px;
  margin-right: 11px; }

.Details_cvvtext__1L_wF {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px; }

.Details_ccv__3uaTl {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: start; }

.Details_ccv__3uaTl input {
  width: 30%;
  margin-left: 36px; }

.Details_acriss__vMXHn {
  width: 40%;
  height: 150px;
  margin-left: 8px;
  margin-top: 15px;
  margin-bottom: 0; }

.Details_included__2bx-v {
  height: 50px;
  width: 100%;
  margin: 5px auto;
  margin-right: 0; }

.Details_inc__2_8rF img {
  width: 15px; }

.Details_inc__2_8rF {
  margin: 5px;
  /* display: inline-block; */
  text-align: center; }

.Details_priceholder__1z_xL {
  font-size: 20px;
  color: orange;
  width: 100%;
  text-align: right;
  margin-top: -8px; }

.Details_priceday__2cA9x {
  font-size: 32px;
  font-weight: 500;
  color: orange;
  text-align: right;
  align-self: flex-end; }

.Details_pricerow__1ysqc {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100px; }

.Details_priceday__2cA9x span {
  font-size: 20px; }

.Details_resbtn__2usma {
  height: 80px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px; }

.Details_inc2__3K6Ed {
  margin-top: 0;
  margin-right: 0;
  padding-left: 20px;
  margin-bottom: 0;
  width: 100%;
  text-align: left;
  font-size: 12px; }

.Details_inc2__3K6Ed img {
  width: 10px; }

.Details_included2__no-gY {
  height: 150px;
  width: 100%;
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }

.Details_details2__3wO9B {
  position: relative;
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  z-index: 5000; }

.Details_incholder__2c0La {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; }

@media (min-width: 768px) {
  .Details_locationd__3yS1- {
    display: table-cell;
    height: 110px;
    vertical-align: middle;
    width: 25%;
    margin: auto 31px; }
  .Details_image__1hMdQ img {
    width: 100%; }
  .Details_locationp__2XNnQ,
  .Details_locationd__3yS1- {
    border-right: solid white 1px; }
  .Details_extrabox__4A2lA input {
    width: 20px; }
  .Details_location4__1bx7Q {
    display: table-cell;
    height: 110px;
    vertical-align: middle;
    width: 25%;
    margin: auto 10px; }
  .Details_holder__3w1PJ {
    display: flex;
    flex-direction: column; }
  .Details_name__GKSlv {
    font-size: 16px; }
  .Details_openhours__1oG2p {
    font-size: 24px; }
  .Details_instructions__2XJSs {
    font-size: 20px; }
  .Details_container__Yqu5w {
    margin-top: 30px; }
  .Details_image__1hMdQ {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%; }
  .Details_sizeheader__2CUjl {
    font-size: 24px;
    font-weight: 400;
    margin-top: 10px; }
  .Details_sizename__1_hxs {
    font-size: 16px;
    margin-top: 10px; }
  .Details_details__25TIV {
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center; }
  .Details_acriss__vMXHn {
    width: 22%;
    margin-left: 8px; }
  .Details_included__2bx-v {
    height: 100px;
    width: 100%;
    margin: 5px auto; }
  .Details_inc__2_8rF img {
    width: 15px; }
  .Details_inc__2_8rF {
    margin: 3px;
    display: inline-block;
    text-align: center; }
  .Details_priceday__2cA9x span {
    font-size: 16px; }
  .Details_resbtn__2usma {
    height: 80px;
    align-self: center;
    justify-self: center;
    width: 80%; }
  .Details_inc2__3K6Ed {
    margin: 3px;
    width: 100%;
    text-align: left;
    font-size: 16px; }
  .Details_inc2__3K6Ed img {
    width: 20px; }
  .Details_included2__no-gY {
    height: 150px;
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
  .Details_details2__3wO9B {
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center; }
  .Details_incholder__2c0La {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; }
  .Details_ccimg__1lrVf {
    justify-content: center;
    align-items: space-evenly;
    display: flex;
    flex-direction: column; }
  .Details_ccform__3TFUH {
    display: flex;
    justify-content: center;
    padding: 20px; }
  .Details_paypal__1yeQn img {
    display: block;
    margin: auto;
    height: 95px; }
  .Details_paypal__1yeQn {
    margin-bottom: 10px; } }

@media (min-width: 968px) {
  .Details_locationd__3yS1- {
    display: table-cell;
    height: 110px;
    vertical-align: middle;
    width: 25%;
    margin: auto 31px; }
  .Details_paypal__1yeQn img {
    display: block;
    margin: auto;
    height: 120px; }
  .Details_cost__3oX1D {
    font-size: 20px;
    margin-top: 2px; }
  .Details_locationp__2XNnQ,
  .Details_locationd__3yS1- {
    border-right: solid white 1px; }
  .Details_location4__1bx7Q {
    display: table-cell;
    height: 110px;
    vertical-align: middle;
    width: 25%;
    margin: auto 10px; }
  .Details_holder__3w1PJ {
    display: flex;
    flex-direction: column; }
  .Details_name__GKSlv {
    font-size: 20px; }
  .Details_openhours__1oG2p {
    font-size: 30px; }
  .Details_instructions__2XJSs {
    font-size: 20px; }
  .Details_container__Yqu5w {
    margin-top: 30px; }
  .Details_image__1hMdQ {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%; }
  .Details_sizeheader__2CUjl {
    font-size: 28px;
    font-weight: 400;
    margin-top: 10px; }
  .Details_sizename__1_hxs {
    font-size: 18px;
    margin-top: 10px; }
  .Details_details__25TIV {
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center; }
  .Details_acriss__vMXHn {
    width: 25%;
    margin-left: 8px; }
  .Details_included__2bx-v {
    height: 100px;
    width: 100%;
    margin: 5px auto;
    margin-right: 0; }
  .Details_inc__2_8rF img {
    width: 15px; }
  .Details_inc__2_8rF {
    margin: 3px;
    display: inline-block;
    text-align: center; }
  .Details_priceday__2cA9x span {
    font-size: 18px; }
  .Details_resbtn__2usma {
    height: 80px;
    align-self: center;
    justify-self: center;
    width: 80%; }
  .Details_inc2__3K6Ed {
    margin: 3px;
    width: 100%;
    text-align: left; }
  .Details_inc2__3K6Ed img {
    width: 20px; }
  .Details_included2__no-gY {
    height: 150px;
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
  .Details_details2__3wO9B {
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center; }
  .Details_incholder__2c0La {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; } }

@media (min-width: 1024px) {
  .Details_name__GKSlv {
    font-size: 20px; }
  .Details_ccnumbertxt__1Txc6 {
    width: 63px;
    margin-right: 17px; }
  .Details_openhours__1oG2p {
    font-size: 30px; }
  .Details_instructions__2XJSs {
    font-size: 20px; }
  .Details_ccholder1__Hw8Us {
    display: flex;
    margin-bottom: 15px; }
  .Details_ccholder__2n_m9 {
    margin-top: auto;
    margin-left: 30px;
    margin-bottom: auto;
    width: auto; }
  .Details_ccheader__2OcjA {
    text-align: center; }
  .Details_ccnumber__2Dhfr {
    width: 60%;
    margin-left: 40px;
    margin-right: 20px;
    display: flex; }
  .Details_ccexp__cN6TG {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-left: 40px; }
  .Details_ccyear__1jQfG input {
    width: 20%;
    margin: auto; }
  .Details_ccmonth__17h5w input {
    width: 20%;
    margin-right: 15px; }
  .Details_alert1__1zKsq {
    width: 100%;
    text-align: center; }
  .Details_ccyear__1jQfG {
    width: 100%; }
  .Details_ccmonth__17h5w {
    width: 100%;
    display: flex; }
  .Details_exptext__3nA-q {
    margin: auto; }
  .Details_cvvtext__1L_wF {
    margin: auto; }
  .Details_ccv__3uaTl {
    margin-top: 0;
    width: 25%;
    display: flex; }
  .Details_ccv__3uaTl input {
    width: 65%;
    margin-left: 16px; } }

@media (min-width: 1440px) {
  .Details_locholder__3K2xu {
    display: flex;
    justify-content: center;
    align-items: center; }
  .Details_locationp__2XNnQ {
    display: table-cell;
    height: 110px;
    vertical-align: middle;
    width: 25%;
    margin: auto 10px; }
  .Details_locationd__3yS1- {
    display: table-cell;
    height: 110px;
    vertical-align: middle;
    width: 25%;
    margin: auto 31px; }
  .Details_locationp__2XNnQ,
  .Details_locationd__3yS1- {
    border-right: solid white 1px; }
  .Details_location4__1bx7Q {
    display: table-cell;
    height: 110px;
    vertical-align: middle;
    width: 25%;
    margin: auto 10px; }
  .Details_holder__3w1PJ {
    display: flex;
    flex-direction: column; }
  .Details_name__GKSlv {
    font-size: 20px; }
  .Details_openhours__1oG2p {
    font-size: 30px; }
  .Details_instructions__2XJSs {
    font-size: 20px; }
  .Details_container__Yqu5w {
    margin-top: 30px; }
  .Details_image__1hMdQ {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%; }
  .Details_sizeheader__2CUjl {
    font-size: 28px;
    font-weight: 400;
    margin-top: 10px; }
  .Details_sizename__1_hxs {
    font-size: 18px;
    margin-top: 10px; }
  .Details_details__25TIV {
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center; }
  .Details_acriss__vMXHn {
    width: 25%;
    margin-left: 8px; }
  .Details_included__2bx-v {
    height: 100px;
    width: 100%;
    margin: 5px auto;
    margin-right: 0; }
  .Details_inc__2_8rF img {
    width: 15px; }
  .Details_inc__2_8rF {
    margin: 3px;
    display: inline-block;
    text-align: center; }
  .Details_priceday__2cA9x span {
    font-size: 18px; }
  .Details_resbtn__2usma {
    height: 80px;
    align-self: center;
    justify-self: center;
    width: 80%; }
  .Details_inc2__3K6Ed {
    margin: 3px;
    width: 100%;
    text-align: left;
    font-size: 20px; }
  .Details_inc2__3K6Ed img {
    width: 20px; }
  .Details_included2__no-gY {
    height: 150px;
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
  .Details_details2__3wO9B {
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center; }
  .Details_incholder__2c0La {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; } }

.Results_locholder__1EmCu {
  display: flex;
  justify-content: center;
  align-items: center; }

.Results_locationp__1Lmkn,
.Results_locationd__3_21m {
  display: table-cell;
  height: 85px;
  vertical-align: middle;
  width: 50%;
  margin: auto 5px; }

.Results_locationd1__1NCqn {
  display: table-cell;
  height: 85px;
  vertical-align: middle;
  width: 0;
  margin: auto 5px; }

.Results_title__2h0Vo {
  font-size: 14px; }

.Results_locationp__1Lmkn {
  border-right: solid white 1px; }

.Results_location4__1eXYd {
  display: table-cell;
  height: 85px;
  vertical-align: middle;
  width: 0%;
  margin: auto 10px; }

.Results_holder__2TaJW {
  margin-top: 12px;
  display: flex;
  margin-left: 8px;
  flex-direction: column; }

.Results_sizeheader__2b_56 {
  font-size: 24px;
  font-weight: 400;
  margin-top: 10px; }
  @media (min-width: 64em) {
    .Results_sizeheader__2b_56 {
      font-size: 30px; } }

.Results_name__2GOHe {
  margin-top: 3px;
  font-size: 14px; }

.Results_openhours__2_Fip {
  font-size: 14px; }

.Results_instructions__16e3U {
  font-size: 14px; }

.Results_container__cdX92 {
  margin-top: 30px; }

.Results_image__3T1jb {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46%;
  height: 200px; }

.Results_image__3T1jb img {
  width: 100%; }

.Results_sizename__3jEDR {
  font-size: 16px;
  margin-top: 10px; }

.Results_details2__1FDys {
  position: relative; }
  @media (min-width: 48em) {
    .Results_details2__1FDys {
      width: 45%;
      height: 100%;
      display: flex;
      justify-content: center; } }
  @media (min-width: 64em) {
    .Results_details2__1FDys {
      font-size: 30px; } }

.Results_details__3S1fH {
  position: relative; }
  @media (min-width: 48em) {
    .Results_details__3S1fH {
      width: 45%;
      height: 100%;
      display: flex;
      justify-content: center; } }
  @media (min-width: 64em) {
    .Results_details__3S1fH {
      font-size: 30px; } }

.Results_acriss__1vxhs {
  width: 50%;
  height: 150px;
  margin-left: 8px;
  margin-top: 15px;
  margin-bottom: 0; }

.Results_included__1uwpF {
  height: auto;
  width: 100%;
  margin-top: 5px;
  margin-right: auto;
  margin-left: 10px;
  padding-bottom: 10px;
  margin-bottom: 2px; }

.Results_inc__2fmAf img {
  width: 15px; }

.Results_inc__2fmAf {
  margin: 5px;
  display: inline-block;
  text-align: center; }

.Results_priceholder__SCEx_ {
  position: absolute;
  bottom: 10px;
  font-size: 20px;
  color: orange;
  width: 100%;
  text-align: right; }

.Results_priceday__2jid2 {
  font-size: 32px;
  font-weight: 500;
  color: orange;
  text-align: right;
  position: absolute;
  bottom: 30px;
  width: 100%; }

.Results_priceday__2jid2 span {
  font-size: 18px; }

.Results_resbtn__1G6NX {
  height: 80px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 60px; }

.Results_inc2__3tMEe {
  margin: 3px;
  width: 50%;
  text-align: left;
  font-size: 20px; }

.Results_inc2__3tMEe img {
  width: 20px; }

.Results_included2__1a7We {
  height: 150px;
  width: 100%;
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }

.Results_incholder__2HmqT {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; }

@media (min-width: 768px) {
  .Results_locationd1__1NCqn {
    display: table-cell;
    height: 150px;
    vertical-align: middle;
    width: 25%;
    margin: auto 31px; }
  .Results_title__2h0Vo {
    font-size: 18px; }
  .Results_locationp__1Lmkn,
  .Results_locationd__3_21m {
    height: 150px; }
  .Results_location4__1eXYd {
    display: table-cell;
    height: 150px;
    vertical-align: middle;
    width: 25%;
    margin: auto 10px; }
  .Results_holder__2TaJW {
    display: flex;
    flex-direction: column; }
  .Results_name__2GOHe {
    font-size: 16px; }
  .Results_openhours__2_Fip {
    font-size: 22px; }
  .Results_instructions__16e3U {
    font-size: 20px; }
  .Results_container__cdX92 {
    margin-top: 30px; }
  .Results_image__3T1jb {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%; }
  .Results_image__3T1jb img {
    width: 70%; }
  .Results_sizename__3jEDR {
    font-size: 16px;
    margin-top: 10px; }
  .Results_acriss__1vxhs {
    width: 22%;
    margin-left: 8px; }
  .Results_included__1uwpF {
    height: 100px;
    width: 100%;
    margin: 5px auto; }
  .Results_inc__2fmAf img {
    width: 15px; }
  .Results_inc__2fmAf {
    margin: 3px;
    display: inline-block;
    text-align: center;
    font-size: 16px; }
  .Results_priceholder__SCEx_ {
    position: absolute;
    bottom: 10px;
    font-size: 15px;
    color: orange;
    width: 200px;
    text-align: right; }
  .Results_priceday__2jid2 {
    font-size: 28px;
    font-weight: 500;
    color: orange;
    text-align: right;
    position: absolute;
    bottom: 30px;
    width: 200px; }
  .Results_priceday__2jid2 span {
    font-size: 16px; }
  .Results_resbtn__1G6NX {
    height: 80px;
    align-self: center;
    justify-self: center;
    width: 80%; }
  .Results_inc2__3tMEe {
    margin: 3px;
    width: 50%;
    text-align: left; }
  .Results_inc2__3tMEe img {
    width: 20px; }
  .Results_included2__1a7We {
    height: 150px;
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
  .Results_incholder__2HmqT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; } }

@media (min-width: 968px) {
  .Results_locationd1__1NCqn {
    display: table-cell;
    vertical-align: middle;
    width: 25%;
    margin: auto 31px; }
  .Results_location4__1eXYd {
    display: table-cell;
    vertical-align: middle;
    width: 25%;
    margin: auto 10px; }
  .Results_holder__2TaJW {
    display: flex;
    flex-direction: column; }
  .Results_name__2GOHe {
    font-size: 20px; }
  .Results_openhours__2_Fip {
    font-size: 28px; }
  .Results_instructions__16e3U {
    font-size: 20px; }
  .Results_container__cdX92 {
    margin-top: 30px; }
  .Results_image__3T1jb {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%; }
  .Results_image__3T1jb img {
    width: 70%; }
  .Results_sizename__3jEDR {
    font-size: 18px;
    margin-top: 10px; }
  .Results_details__3S1fH {
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center; }
  .Results_acriss__1vxhs {
    width: 25%;
    margin-left: 8px; }
  .Results_included__1uwpF {
    height: 100px;
    width: 100%;
    margin: 5px auto;
    margin-right: 0; }
  .Results_inc__2fmAf img {
    width: 15px; }
  .Results_inc__2fmAf {
    margin: 3px;
    display: inline-block;
    text-align: center;
    font-size: 20px; }
  .Results_priceholder__SCEx_ {
    position: absolute;
    bottom: 10px;
    font-size: 20px;
    color: orange;
    width: 100%;
    text-align: right; }
  .Results_priceday__2jid2 {
    font-size: 32px;
    font-weight: 500;
    color: orange;
    text-align: right;
    position: absolute;
    bottom: 30px;
    width: 100%; }
  .Results_priceday__2jid2 span {
    font-size: 18px; }
  .Results_resbtn__1G6NX {
    height: 80px;
    align-self: center;
    justify-self: center;
    width: 80%; }
  .Results_inc2__3tMEe {
    margin: 3px;
    width: 50%;
    text-align: left; }
  .Results_inc2__3tMEe img {
    width: 20px; }
  .Results_included2__1a7We {
    height: 150px;
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
  .Results_incholder__2HmqT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; } }

@media (min-width: 1024px) {
  .Results_name__2GOHe {
    font-size: 20px; }
  .Results_instructions__16e3U {
    font-size: 20px; } }

@media (min-width: 1440px) {
  .Results_locholder__1EmCu {
    display: flex;
    justify-content: center;
    align-items: center; }
  .Results_locationp__1Lmkn,
  .Results_locationd__3_21m {
    display: table-cell;
    vertical-align: middle;
    width: 25%;
    margin: auto 10px; }
  .Results_locationd1__1NCqn {
    display: table-cell;
    vertical-align: middle;
    width: 25%;
    margin: auto 31px; }
  .Results_location4__1eXYd {
    display: table-cell;
    vertical-align: middle;
    width: 25%;
    margin: auto 10px; }
  .Results_holder__2TaJW {
    display: flex;
    flex-direction: column; }
  .Results_name__2GOHe {
    font-size: 20px; }
  .Results_openhours__2_Fip {
    font-size: 28px; }
  .Results_instructions__16e3U {
    font-size: 20px; }
  .Results_container__cdX92 {
    margin-top: 30px; }
  .Results_image__3T1jb {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%; }
  .Results_image__3T1jb img {
    width: 70%; }
  .Results_sizename__3jEDR {
    font-size: 18px;
    margin-top: 10px; }
  .Results_details__3S1fH {
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center; }
  .Results_acriss__1vxhs {
    width: 25%;
    margin-left: 8px; }
  .Results_included__1uwpF {
    height: 100px;
    width: 100%;
    margin: 5px auto;
    margin-right: 0; }
  .Results_inc__2fmAf img {
    width: 15px; }
  .Results_inc__2fmAf {
    margin: 3px;
    display: inline-block;
    text-align: center; }
  .Results_priceholder__SCEx_ {
    position: absolute;
    bottom: 10px;
    font-size: 20px;
    color: orange;
    width: 100%;
    text-align: right; }
  .Results_priceday__2jid2 {
    font-size: 32px;
    font-weight: 500;
    color: orange;
    text-align: right;
    position: absolute;
    bottom: 30px;
    width: 100%; }
  .Results_priceday__2jid2 span {
    font-size: 18px; }
  .Results_resbtn__1G6NX {
    height: 80px;
    align-self: center;
    justify-self: center;
    width: 80%; }
  .Results_inc2__3tMEe {
    margin: 3px;
    width: 50%;
    text-align: left; }
  .Results_inc2__3tMEe img {
    width: 20px; }
  .Results_included2__1a7We {
    height: 150px;
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
  .Results_incholder__2HmqT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .Results_inc__2fmAf {
    margin: 3px;
    width: 100%;
    text-align: left;
    font-size: 16px; }
  /* STYLES GO HERE */ }

.Confirmation_bookingtitle__2tKrO {
  text-align: center;
  margin: 1.5rem
}

.Confirmation_confirmationnumber__1rqLx {
  text-align: center;
  justify-content: center;
  display: flex;
  margin-bottom: 1.5rem;

}

.Confirmation_confirmationnumber1__2r5wh {
  color: red;
  font-size: 24px;
  margin-left: 1.5rem;
}

.Confirmation_email__2kosb {
  margin-bottom: 3rem;
}

.Confirmation_email__2kosb span {
  color: gray;
}

.Confirmation_total__2lG9W {
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.Confirmation_driver__2nm3i {
  margin: 5px auto;
}
